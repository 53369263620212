import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import FoodTruck from "../images/food-truck2.jpg"
import Bg1 from "../images/tourplan_bg1.jpg"
import Bg2 from "../images/tourplan_bg2.jpg"
import Bg3 from "../images/tourplan_bg3.jpg"
import Bg5 from "../images/tourplan_bg5.jpg"
import Bg6 from "../images/tourplan_bg6.jpg"
import Bg7 from "../images/tourplan_bg7.jpg"
import Bg8 from "../images/tourplan_bg8.jpg"
import html2canvas from 'html2canvas';
import { useForm } from "react-hook-form";

import GBold from '../images/Gotham-Bold.ttf';
import GRegular from '../images/Gotham-Regular.ttf';

import '../scss/banner.scss';

import Standorte from "../standorte.json"

export default function Banner() {
  const { register, handleSubmit, watch, errors, setValue } = useForm();
  const bgImage = watch('bg');
  const date = watch('date');
  const texts = watch();
  const [dates, setDates] = useState([])

  useEffect(() => {
    const start = new Date(date);
    const weekdays = Array.from(Array(10).keys()).map(i => {
      const newDate = +start + (1000 * 60 * 60 * 24 * i);
      return new Date(newDate);
    });
    setDates(weekdays);
    Standorte.forEach((standort, index) => {
      if (standort.datum === date) {
        setValue('mo_place', standort.text);
        setValue('mo_time', standort.zeit);
        if (Standorte[index + 1] && !Standorte[index + 1].text.toLocaleLowerCase().includes('geschlossen')) {
          setValue('di_place', Standorte[index + 1].text);
          setValue('di_time', Standorte[index + 1].zeit);
        } else { setValue('di_place', 'Geschlossen'); setValue('di_time', ''); }
        if (Standorte[index + 2] && !Standorte[index + 2].text.toLocaleLowerCase().includes('geschlossen')) {
          setValue('mi_place', Standorte[index + 2].text);
          setValue('mi_time', Standorte[index + 2].zeit);
        } else { setValue('mi_place', 'Geschlossen'); setValue('mi_time', ''); }
        if (Standorte[index + 3] && !Standorte[index + 3].text.toLocaleLowerCase().includes('geschlossen')) {
          setValue('do_place', Standorte[index + 3].text);
          setValue('do_time', Standorte[index + 3].zeit);
        } else { setValue('do_place', 'Geschlossen'); setValue('do_time', ''); }
        if (Standorte[index + 4] && !Standorte[index + 4].text.toLocaleLowerCase().includes('geschlossen')) {
          setValue('fr_place', Standorte[index + 4].text);
          setValue('fr_time', Standorte[index + 4].zeit);
        } else { setValue('fr_place', 'Geschlossen'); setValue('fr_time', ''); }
        if (Standorte[index + 5] && !Standorte[index + 5].text.toLocaleLowerCase().includes('geschlossen')) {
          setValue('sa_place', Standorte[index + 5].text);
          setValue('sa_time', Standorte[index + 5].zeit);
        } else { setValue('sa_place', 'Geschlossen'); setValue('sa_time', ''); }
        if (Standorte[index + 6] && !Standorte[index + 6].text.toLocaleLowerCase().includes('geschlossen')) {
          setValue('so_place', Standorte[index + 6].text);
          setValue('so_time', Standorte[index + 6].zeit);
        } else { setValue('so_place', 'Geschlossen'); setValue('so_time', ''); }

      }
    })
  }, [date])

  const onSubmit = data => {
    document.getElementById("banner").style.position = 'absolute';
    document.querySelector('body').style.overflow = 'hidden';

    html2canvas(document.getElementById("banner"), {
      width: 900,
      height: 900,
      scrollX: -.5,
      scrollY: -window.scrollY
    })
      .then(canvas => {
        document.getElementById("banner").style.position = '';
        document.querySelector('body').style.overflow = '';
        let a = document.createElement('a')
        a.href = canvas.toDataURL('image/jpeg', 1.0);
        a.download = 'banner.jpg';
        a.click();
      })
  };

  const style = `
    @font-face {
      font-family: 'GothamRegular';
      src: url('${GRegular}')  format('truetype')
    }

    @font-face {
      font-family: 'GothamBold';
      src: url('${GBold}')  format('truetype')
    }
  `;

  const options = {
    day: '2-digit',
    month: '2-digit'
  }

  const options2 = {
    day: 'numeric',
    month: 'short'
  }

  return (
    <Layout>
      <SEO title="Banner" />
      <style>
        {style}
      </style>
      <div
        className="site-blocks-cover inner-page overlay"
        style={{ backgroundImage: `url(${FoodTruck})` }}
      >
        <div className="row align-items-center justify-content-center">
          <div className="col-12 text-center">
            <div className="card banner-form" style={{ width: '40rem' }}>
              <div className="card-body text-left">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group mb-3 row">
                    <label className="form-label col-1 col-form-label"></label>
                    <div className="col-5">
                      <input type="date" className="form-control" name="date" ref={register} />
                    </div>
                    <label className="form-label col-2 col-form-label">Image</label>
                    <div className="col-4">
                      <select className="form-select w-100" defaultValue="" name="bg" ref={register}>
                        <option value="">-</option>
                        <option value={Bg1}>Bg1</option>
                        <option value={Bg2}>Bg2</option>
                        <option value={Bg3}>Bg3</option>
                        <option value={Bg5}>Bg5</option>
                        <option value={Bg6}>Bg6</option>
                        <option value={Bg7}>Bg7</option>
                        <option value={Bg8}>Bg8</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="form-label col-1 col-form-label">Mo</label>
                    <div className="col-7">
                      <input type="text" className="form-control" name="mo_place" ref={register} placeholder="" />
                    </div>
                    <div className="col-4">
                      <input type="text" className="form-control" name="mo_time" ref={register} placeholder="" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="form-label col-1 col-form-label">Di</label>
                    <div className="col-7">
                      <input type="text" className="form-control" name="di_place" ref={register} placeholder="" />
                    </div>
                    <div className="col-4">
                      <input type="text" className="form-control" name="di_time" ref={register} placeholder="" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="form-label col-1 col-form-label">Mi</label>
                    <div className="col-7">
                      <input type="text" className="form-control" name="mi_place" ref={register} placeholder="" />
                    </div>
                    <div className="col-4">
                      <input type="text" className="form-control" name="mi_time" ref={register} placeholder="" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="form-label col-1 col-form-label">Do</label>
                    <div className="col-7">
                      <input type="text" className="form-control" name="do_place" ref={register} placeholder="" />
                    </div>
                    <div className="col-4">
                      <input type="text" className="form-control" name="do_time" ref={register} placeholder="" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="form-label col-1 col-form-label">Fr</label>
                    <div className="col-7">
                      <input type="text" className="form-control" name="fr_place" ref={register} placeholder="" />
                    </div>
                    <div className="col-4">
                      <input type="text" className="form-control" name="fr_time" ref={register} placeholder="" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="form-label col-1 col-form-label">Sa</label>
                    <div className="col-7">
                      <input type="text" className="form-control" name="sa_place" ref={register} placeholder="" />
                    </div>
                    <div className="col-4">
                      <input type="text" className="form-control" name="sa_time" ref={register} placeholder="" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="form-label col-1 col-form-label">So</label>
                    <div className="col-7">
                      <input type="text" className="form-control" name="so_place" ref={register} placeholder="" />
                    </div>
                    <div className="col-4">
                      <input type="text" className="form-control" name="so_time" ref={register} placeholder="" />
                    </div>
                  </div>
                  <div className="form-footer">
                    <button type="submit" className="btn btn-primary">Download</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {bgImage && <div id="banner" className={bgImage && bgImage.match(/\/static\/([^-]*)/)[1]} style={{ backgroundImage: `url(${bgImage})` }}>
        <span className="title">Tourplan vom {new Intl.DateTimeFormat('de-DE', options2).format(dates[0])} bis {new Intl.DateTimeFormat('de-DE', options2).format(dates[6])}</span>
        {date && <div className="inblock"><div className="banner-grid">
          <div className={`wd ${texts.mo_place === 'Geschlossen' ? 'closed' : ''}`}><span className="wddesc">{new Intl.DateTimeFormat('de-DE', { weekday: 'short' }).format(dates[0])}</span><br/>{new Intl.DateTimeFormat('de-DE', options).format(dates[0])}</div>
          <div className={`${texts.mo_place === 'Geschlossen' ? 'closed' : ''}`}><span className="place">{texts.mo_place}</span><br/>{texts.mo_time}</div>
          <div className={`wd ${texts.di_place === 'Geschlossen' ? 'closed' : ''}`}><span className="wddesc">{new Intl.DateTimeFormat('de-DE', { weekday: 'short' }).format(dates[1])}</span><br/>{new Intl.DateTimeFormat('de-DE', options).format(dates[1])}</div>
          <div className={`${texts.di_place === 'Geschlossen' ? 'closed' : ''}`}><span className="place">{texts.di_place}</span><br/>{texts.di_time}</div>
          <div className={`wd ${texts.mi_place === 'Geschlossen' ? 'closed' : ''}`}><span className="wddesc">{new Intl.DateTimeFormat('de-DE', { weekday: 'short' }).format(dates[2])}</span><br/>{new Intl.DateTimeFormat('de-DE', options).format(dates[2])}</div>
          <div className={`${texts.mi_place === 'Geschlossen' ? 'closed' : ''}`}><span className="place">{texts.mi_place}</span><br/>{texts.mi_time}</div>
          <div className={`wd ${texts.do_place === 'Geschlossen' ? 'closed' : ''}`}><span className="wddesc">{new Intl.DateTimeFormat('de-DE', { weekday: 'short' }).format(dates[3])}</span><br/>{new Intl.DateTimeFormat('de-DE', options).format(dates[3])}</div>
          <div className={`${texts.do_place === 'Geschlossen' ? 'closed' : ''}`}><span className="place">{texts.do_place}</span><br/>{texts.do_time}</div>
          <div className={`wd ${texts.fr_place === 'Geschlossen' ? 'closed' : ''}`}><span className="wddesc">{new Intl.DateTimeFormat('de-DE', { weekday: 'short' }).format(dates[4])}</span><br/>{new Intl.DateTimeFormat('de-DE', options).format(dates[4])}</div>
          <div className={`${texts.fr_place === 'Geschlossen' ? 'closed' : ''}`}><span className="place">{texts.fr_place}</span><br/>{texts.fr_time}</div>
          <div className={`wd ${texts.sa_place === 'Geschlossen' ? 'closed' : ''}`}><span className="wddesc">{new Intl.DateTimeFormat('de-DE', { weekday: 'short' }).format(dates[5])}</span><br/>{new Intl.DateTimeFormat('de-DE', options).format(dates[5])}</div>
          <div className={`${texts.sa_place === 'Geschlossen' ? 'closed' : ''}`}><span className="place">{texts.sa_place}</span><br/>{texts.sa_time}</div>
          <div className={`wd ${texts.so_place === 'Geschlossen' ? 'closed' : ''}`}><span className="wddesc">{new Intl.DateTimeFormat('de-DE', { weekday: 'short' }).format(dates[6])}</span><br/>{new Intl.DateTimeFormat('de-DE', options).format(dates[6])}</div>
          <div className={`${texts.so_place === 'Geschlossen' ? 'closed' : ''}`}><span className="place">{texts.so_place}</span><br/>{texts.so_time}</div>
        </div></div>}
      </div>}
    </Layout>
  )
}
